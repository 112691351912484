import * as React from "react"
import { useState, useRef, useCallback, useEffect } from "react"

import { Loading } from 'react-loading-dot'

import logoImage from '../images/logo.svg'
import closeImage from '../images/close.svg'
import desktop1 from '../videos/desktop-1-audio.mp4'
import desktop2 from '../videos/desktop-2-audio.mp4'
import mobile1 from '../videos/mobile-1-audio.mp4'
import mobile2 from '../videos/mobile-2-audio.mp4'

import Tilt from 'react-parallax-tilt';
import useMediaQuery from "../hooks/use-media-query"

const navTexts = [
  'Creative Agency Toronto',
  'Providing Better Futures',
  'Contact WWC™ Now'
]

const IndexPage = () => {
  const [aboutModalOpen, setAboutModalOpen] = useState(false)
  const [introFinished, setIntroFinished] = useState(false)
  const [playing, setPlaying] = useState(true)
  const [muted, setMuted] = useState(true)

  const index = useRef(0);
  const [navText, setNavText] = useState(navTexts[0]);

  const isHorizontalVideo = useMediaQuery('(min-aspect-ratio: 1/1)');

  const touchDevice = useMediaQuery('(hover: none)');

  const updateNavText = useCallback(() => {
    if (index.current >= navTexts.length) {
      index.current = 0
    }

    setNavText(navTexts[index.current]);
    index.current++;
  }, []);

  useEffect(() => {
    const intervalID = setInterval(updateNavText, 2000);
    return () => clearInterval(intervalID);
  }, [updateNavText])

  const getActiveVideo = () => {
    return introFinished ? videoRef.current : introVideoRef.current;
  }

  const onPause = () => {
    playing ? getActiveVideo().pause() : getActiveVideo().play();

    setPlaying(!playing)
  }

  const onMute = () => {
    document.querySelectorAll('video').forEach(vid => vid.muted = !muted);
    setMuted(!muted)
  }

  const videoRef = useRef(null)
  const introVideoRef = useRef(null)

  return (
    <main className={`h-screen-webkit overflow-hidden ${aboutModalOpen && 'modal-open'}`}>
      <div className="squares-top z-50"></div>
      <div className="squares-bottom z-50"></div>
      <div className={`absolute h-screen-webkit w-screen bg-background z-40 transition-transform transform-gpu duration-[400ms] ${!aboutModalOpen && 'translate-x-[-120%]'}`}>
        <div className="max-w-7xl px-8 mx-auto pt-7 lg:pt-14 flex justify-between flex-col h-full" id="modal">
          <header className="flex justify-between pb-7">
            <img src={logoImage} alt="WWC™" />

            <button type="button" onClick={() => setAboutModalOpen(!aboutModalOpen)} className="flex items-center uppercase">
              Close
              <img src={closeImage} alt="Close nav" className="w-[24px] h-[24px]" />
            </button>
          </header>

          <Tilt
            tiltEnable={aboutModalOpen}
            tiltReverse={true}
            trackOnWindow={true}
            tiltMaxAngleX={touchDevice ? 20 : 9}
            tiltMaxAngleY={touchDevice ? 20 : 9}
          >
            <div className={`font-display lg:text-[200px] md:text-[120px] text-[60px] leading-[0.776] uppercase nav-text ${aboutModalOpen && 'nav-text--visible'} pointer-events-none md:text-left text-center`}>
              <span style={{wordSpacing: '100vw'}} dangerouslySetInnerHTML={{__html: navText}}></span>
            </div>
          </Tilt>

          <nav className="font-body flex justify-between pt-7 pb-14 lg:flex-row flex-col">
            <div>
              <a href="mailto:info@wwc.studio" className="hover-underline-animation">Contact</a>
            </div>
            <div>
              <a href="https://www.instagram.com/by.wwc/" className="hover-underline-animation underline-offset-1" rel="me nofollow noopener noreferrer" target="_blank">Instagram</a>
            </div>
            <div>
              <a href="https://www.linkedin.com/company/world-wide-cool/" className="hover-underline-animation underline-offset-1" rel="me nofollow noopener noreferrer" target="_blank">LinkedIn</a>
            </div>
            <div>
              <a href="https://www.are.na/world-wide-cool" className="hover-underline-animation underline-offset-1" rel="me nofollow noopener noreferrer" target="_blank">Are.na</a>
            </div>
          </nav>
        </div>
      </div>

      <div className="max-w-7xl px-8 mx-auto pt-7 lg:pt-14" id="body">
        <header className="flex justify-between z-10 relative">
          <h1 className="flex">
            <img src={logoImage} alt="WWC™" />
            <span className="sr-only">World Wide Cool™ - Creative Agency Toronto</span>
          </h1>

          <button type="button" onClick={() => setAboutModalOpen(!aboutModalOpen)} className="uppercase hover-underline-animation underline-offset-1">About</button>
        </header>


        <div className="video-wrapper z-0">
          {isHorizontalVideo === undefined ? <Loading background="#000" size="0.5rem" margin="0.2rem" />
            :
            <>
              <video src={isHorizontalVideo ? desktop1 : mobile1}
                autoPlay
                disableRemotePlayback
                muted
                className={`video transition-opacity ease-linear ${introFinished && 'opacity-0'} ${isHorizontalVideo ? 'video-horizontal' : 'video-vertical'}`}
                playsInline
                ref={introVideoRef}
                onEnded={() => {
                  setIntroFinished(true);
                  videoRef.current.play();
                }} />

              <video
                disableRemotePlayback
                ref={videoRef}
                playsInline
                preload="auto"
                src={isHorizontalVideo ? desktop2 : mobile2}
                muted
                loop
                className={`video transition-opacity ease-linear ${!introFinished && 'opacity-0'}  ${isHorizontalVideo ? 'video-horizontal' : 'video-vertical'}`} />
            </>
          }
        </div>

        <div className="w-full absolute bottom-0 left-0">
          <div className="max-w-7xl px-8 mx-auto">
          <footer className="flex justify-between z-10 relative pb-7 lg:pb-14 w-full">
            <button type="button" onClick={onPause} className="font-body hover-underline-animation underline-offset-1">{playing ? 'Pause' : 'Play' }</button>

            <button type="button" onClick={onMute} className="font-body hover-underline-animation underline-offset-1">{muted ? 'Unmute' : 'Mute' }</button>
          </footer>
          </div>
        </div>
      </div>
    </main>
  )
}

export default IndexPage

export const Head = () => {
  return (
    <>
      <title>World Wide Cool - Creative Agency Toronto</title>
      <meta name="description" content="Providing Better Futures. Providing Better Futures. Providing Better Futures. Providing Better Futures." />
      <meta property="og:image" content="" />
    </>
  )
}
